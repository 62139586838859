import React, { useEffect, useState } from 'react';
import ButtonWithHover from './ButtonWithHover';
import './Hero.css';

const Hero = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const headerHeight = document.querySelector('.header-wrapper').offsetHeight;
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - headerHeight;

    window.scrollTo({
      top: elementPosition,
      behavior: 'smooth'
    });
  };
  return (
      <section className="hero-wrapper bebas-neue-regular">
        <div className="hero-content">
          <div className="tagline-container">
            <div className={"tagline"}>
              <div className="hero-text">LAUNCHING Small Businesses</div>
              <button className="cta-button barlow-condensed-semibold" onClick={() => scrollToSection('footer')}>ENQUIRE NOW</button>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Hero;
