import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Branding from './components/Branding';
import Development from './components/Development';
import Footer from './components/Footer';
import './App.css';
import './assets/fonts.css';

function App() {
  const [shrink, setShrink] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [activeSection, setActiveSection] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const [footerVisible, setFooterVisible] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setScrollY(currentScrollY);

    if (currentScrollY > 180) {
      setShrink(true);
    } else {
      setShrink(false);
    }

    const footer = document.querySelector('.footer-wrapper');
    const footerTop = footer.getBoundingClientRect().top;

    if (footerTop <= window.innerHeight) {
      setFooterVisible(true);
    } else {
      setFooterVisible(false);
    }

    const sections = document.querySelectorAll('section');
    sections.forEach(section => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
        setActiveSection(section.id);
      }
    });
  };

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
      <div className="App">
        <Header shrink={shrink} scrollY={scrollY} footerVisible={footerVisible} />
        <div className={`site-wrapper ${shrink ? 'small' : ''}`}>
          <Hero />
          <About mousePosition={mousePosition} active={activeSection === 'about'} />
          <Branding mousePosition={mousePosition} active={activeSection === 'branding'} />
          <Development mousePosition={mousePosition} active={activeSection === 'development'} />
          <Footer mousePosition={mousePosition} />
        </div>
      </div>
  );
}

export default App;
