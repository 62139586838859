import React, { useRef } from 'react';
import './ButtonWithHover.css';

const ButtonWithHover = ({ children }) => {
  const buttonRef = useRef(null);

  const handleMouseEnter = (event) => {
    const button = buttonRef.current;
    const { top, left, width, height } = button.getBoundingClientRect();
    const mouseX = event.clientX - left;
    const mouseY = event.clientY - top;

    const direction = getDirection(mouseX, mouseY, width, height);
    button.setAttribute('data-enter-direction', direction);
    button.style.setProperty('--mouse-x', `${mouseX}px`);
    button.style.setProperty('--mouse-y', `${mouseY}px`);
  };

  const handleMouseLeave = (event) => {
    const button = buttonRef.current;
    const { top, left, width, height } = button.getBoundingClientRect();
    const mouseX = event.clientX - left;
    const mouseY = event.clientY - top;

    const direction = getDirection(mouseX, mouseY, width, height);
    button.setAttribute('data-leave-direction', direction);
    button.style.setProperty('--mouse-x', `${mouseX}px`);
    button.style.setProperty('--mouse-y', `${mouseY}px`);

    // Remove attributes after animation to reset state
    setTimeout(() => {
      button.removeAttribute('data-enter-direction');
      button.removeAttribute('data-leave-direction');
    }, 500); // Match the duration of the animation
  };

  const getDirection = (mouseX, mouseY, width, height) => {
    const halfWidth = width / 2;
    const halfHeight = height / 2;
    const dx = mouseX - halfWidth;
    const dy = mouseY - halfHeight;
    const angle = Math.atan2(dy, dx) * (180 / Math.PI);

    if (angle >= -45 && angle < 45) {
      return 'right';
    } else if (angle >= 45 && angle < 135) {
      return 'bottom';
    } else if (angle >= -135 && angle < -45) {
      return 'top';
    } else {
      return 'left';
    }
  };

  return (
      <button
          className="button-nav barlow-condensed-semibold"
          ref={buttonRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
      >
        <span>{children}</span>
      </button>
  );
};

export default ButtonWithHover;
