import React, { useEffect } from 'react';
import './Development.css';

const Development = ({ mousePosition, active }) => {
  useEffect(() => {
    const handleScroll = () => {
      const images = document.querySelectorAll('.development-image-container .parallax-image');

      images.forEach(image => {
        const speed = image.getAttribute('data-speed');
        const startY = parseInt(image.getAttribute('data-start-y'), 10) || 0;
        const yPos = startY - (window.scrollY * speed) / 100;
        image.style.transform = `translateY(${yPos}px)`;
      });
    };

    if (window.innerWidth >= 1200) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (window.innerWidth >= 1200) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
      <section id="development" className={`section-wrapper ${active ? 'active' : ''}`}>
        <div className='development-header barlow-condensed-semibold'>development</div>
        <div className='development-content-container'>
          <header className='development-text'>
            <h1 className='development-content-header barlow-condensed-semibold'>Bring your online presence to life</h1>
            <p className='development-content-text barlow-regular'>We create responsive, user-friendly websites tailored to your needs, and our hosting solutions ensure your site runs smoothly around the clock.<br /><br />Let us help you build and maintain a powerful online platform.</p>
          </header>
          <div className='development-image-container'>
            <img src={require('../assets/web-1.jpg')} alt="Web1" className="parallax-image" data-speed="2" data-start-y="0" />
            <img src={require('../assets/web-2.jpg')} alt="Web2" className="parallax-image" data-speed="5" data-start-y="250" />
            <img src={require('../assets/web-3.jpg')} alt="Web3" className="parallax-image" data-speed="10" data-start-y="450" />
          </div>
        </div>
      </section>
  );
};

export default Development;
