import React, { useEffect, useRef } from 'react';
import './Branding.css';

const Branding = ({ mousePosition, active }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (event, image) => {
      const rect = image.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const distanceX = Math.abs(event.clientX - centerX);
      const distanceY = Math.abs(event.clientY - centerY);
      const maxDistance = Math.max(rect.width, rect.height);
      const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
      const scale = 1 + (0.2 * (1 - Math.min(distance / maxDistance, 1)));

      let translateX = 0;
      let translateY = 0;

      if (image.classList.contains('top-left')) {
        translateX = -distanceX / 4;
        translateY = -distanceY / 4;
        image.style.transformOrigin = 'bottom right';
      } else if (image.classList.contains('top-right')) {
        translateX = distanceX / 4;
        translateY = -distanceY / 4;
        image.style.transformOrigin = 'bottom left';
      } else if (image.classList.contains('bottom-left')) {
        translateX = -distanceX / 4;
        translateY = distanceY / 4;
        image.style.transformOrigin = 'top right';
      } else if (image.classList.contains('bottom-right')) {
        translateX = distanceX / 4;
        translateY = distanceY / 4;
        image.style.transformOrigin = 'top left';
      }

      image.style.transition = `transform 0.3s ease-out`;
      image.style.transform = `scale(${scale}) translate(${translateX}px, ${translateY}px)`;
    };

    const handleMouseLeave = (image) => {
      image.style.transform = 'scale(1) translate(0px, 0px)';
      image.style.transformOrigin = 'center center';
    };

    const container = containerRef.current;
    const images = container.querySelectorAll('.branding-image-container .image-wrapper');

    if (window.innerWidth >= 1200) {
      images.forEach(image => {
        image.addEventListener('mousemove', (event) => handleMouseMove(event, image));
        image.addEventListener('mouseleave', () => handleMouseLeave(image));
      });
    }

    return () => {
      images.forEach(image => {
        image.removeEventListener('mousemove', (event) => handleMouseMove(event, image));
        image.removeEventListener('mouseleave', () => handleMouseLeave(image));
      });
    };
  }, []);

  return (
      <section id="branding" className={`section-wrapper ${active ? 'active' : ''}`} ref={containerRef}>
        <div className='branding-header barlow-condensed-semibold'>branding</div>
        <div className='branding-content-container'>
          <header className='branding-text'>
            <h1 className='branding-content-header barlow-condensed-semibold'>Bespoke brand design solutions tailored to your unique vision.</h1>
            <p className='branding-content-text barlow-regular'>Our mission is to craft distinctive, memorable, and personable brands that resonate with your audience and make you stand out in your category / market.<br /><br />From Logos to Colour Palettes, Fonts, High-Res AI imagery and Copywriting.</p>
          </header>
          <div className='branding-image-container'>
            <div className="image-wrapper top-left">
              <img src={require('../assets/stock-close.jpg')} alt="Stock Close" className="branding-image" />
            </div>
            <div className="image-wrapper top-right">
              <img src={require('../assets/logo-me-1.png')} alt="Logo ME" className="branding-image" />
            </div>
            <div className="image-wrapper bottom-left">
              <img src={require('../assets/logo-mish-1.png')} alt="Logo Mish" className="branding-image" />
            </div>
            <div className="image-wrapper bottom-right">
              <img src={require('../assets/stock-yoga.jpg')} alt="Stock Yoga" className="branding-image" />
            </div>
          </div>
        </div>
      </section>
  );
};

export default Branding;
