import React from 'react';
import './Header.css';
import logo from '../assets/logo-2.png';
import ButtonWithHover from './ButtonWithHover';

const Header = ({ shrink, footerVisible }) => {
    const headerStyle = footerVisible
        ? { transform: `translateY(-100%)`, transition: 'transform 0.3s ease' }
        : {};

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        const headerHeight = document.querySelector('.header-wrapper').offsetHeight;
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerHeight;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };

    return (
        <header className={`header-wrapper ${shrink ? 'small' : ''}`} style={headerStyle}>
            <div className={`logo-container ${shrink ? 'shrink' : ''}`}>
                <img className="logo" src={logo} alt="Logo" />
            </div>
            <nav className="nav">
                <button onClick={() => scrollToSection('development')}><ButtonWithHover>DEVELOPMENT</ButtonWithHover></button>
                <button onClick={() => scrollToSection('branding')}><ButtonWithHover>BRANDING</ButtonWithHover></button>
                <button onClick={() => scrollToSection('about')}><ButtonWithHover>ABOUT US</ButtonWithHover></button>
            </nav>
        </header>
    );
};

export default Header;
