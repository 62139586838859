import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import './Footer.css';
import logo from '../assets/logo-2.png';
import ButtonWithHover from './ButtonWithHover';

const Footer = () => {
    const form = useRef();
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        enquiry: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setFeedbackMessage('');

        emailjs.sendForm('service_44xfn4u', 'template_ju43qj6', form.current, 'ylVXf_M4Q2dbIJfQc')
            .then((result) => {
                console.log('Email successfully sent!', result.text);
                setFeedbackMessage('Thanks for your enquiry!');
                setIsSubmitting(false);
            }, (error) => {
                console.log('Failed to send email.', error.text);
                setFeedbackMessage('Failed to send email. Please try again.');
                setIsSubmitting(false);
            });

        setFormData({
            name: '',
            phone: '',
            email: '',
            enquiry: ''
        });
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        const headerHeight = document.querySelector('.header-wrapper').offsetHeight;
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerHeight;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };

    return (
        <section id="footer" className="footer-wrapper">
            <div className="footer-links">
                <ButtonWithHover onClick={() => scrollToSection('development')}>DEVELOPMENT</ButtonWithHover>
                <ButtonWithHover onClick={() => scrollToSection('branding')}>BRANDING</ButtonWithHover>
                <ButtonWithHover onClick={() => scrollToSection('about')}>ABOUT US</ButtonWithHover>
            </div>
            <div className="footer-content">
                <div className="footer-logo">
                    <img src={logo} alt="Logo" />
                </div>
                <form ref={form} onSubmit={sendEmail} className="contact-form barlow-regular">
                    <h2>Contact Us</h2>
                    <div className="form-group">
                        <label>Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Phone</label>
                        <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Phone"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Enquiry</label>
                        <textarea
                            name="enquiry"
                            value={formData.enquiry}
                            onChange={handleChange}
                            placeholder="Your Enquiry"
                            rows="6"
                            required
                        />
                    </div>
                    <ButtonWithHover type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Sending...' : 'Send'}
                    </ButtonWithHover>
                    {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}
                </form>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 ME Digital. All rights reserved.</p>
            </div>
        </section>
    );
};

export default Footer;
