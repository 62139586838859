import React, { useEffect, useRef } from 'react';
import './About.css';

const About = ({ mousePosition, active }) => {
    return (
        <section id="about" className={`section-wrapper ${active ? 'active' : ''}`}>
            <div className="about-header barlow-condensed-semibold">about us</div>
            <div className="about-content-container barlow-condensed-semibold">
                <div className="about-text-main">
                    <p>Welcome to ME (Mish & Ed), where we make creating your brand's online identity easy and enjoyable.
                        Unlike big agencies (we’ve both been there!), we skip the complexity and deliver top-notch web branding
                        solutions at a fraction of the cost.<br /><br />
                        Why Choose Us? </p>
                </div>
                <div className="about-text-secondary barlow-regular">
                    <div className="about-text-item">
                        <div className="about-text-item-header">
                            <p>Personal Touch</p>
                        </div>
                        <div className="about-text-item-content">
                            <p>We work closely with you to understand your vision and tailor our services to
                                fit your goals recognising there is no one size fits all.</p>
                        </div>
                    </div>
                    <div className="about-text-item">
                        <div className="about-text-item-header">
                            <p>Fast and Efficient</p>
                        </div>
                        <div className="about-text-item-content">
                            <p>No complex drawn-out processes. We get things done quickly so you can go
                                live and start seeing results sooner.</p>
                        </div>
                    </div>
                    <div className="about-text-item">
                        <div className="about-text-item-header">
                            <p>Budget-Friendly</p>
                        </div>
                        <div className="about-text-item-content">
                            <p>Our streamlined approach means you get professional branding without breaking
                                the bank.</p>
                        </div>
                    </div>
                    <div className="about-text-item">
                        <div className="about-text-item-header">
                            <p>All-in-One Service</p>
                        </div>
                        <div className="about-text-item-content">
                            <p>From logo design to website development and more, we handle everything to give your brand a cohesive online presence.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
